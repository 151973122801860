import { Badge, CalendarMonth, Email, Home, Person, Phone } from '@mui/icons-material';
import { Button, Grid, InputAdornment, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { reset, updateAgentProfile, updateSurveyorProfile } from '../../features/auth/authSlice';
import { updateDetailsSchema } from '../../utils/Schemas';

const UpdateDetailsForm = () => {
  const { user: userDetails, isError, isLoading, isSuccess, message } = useSelector((state) => state.agentAuth);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      firstName: userDetails.Firstname || '',
      lastName: userDetails.Lastname || '',
      address: userDetails.Address || '',
      phone: userDetails.Phone || '',
      signature: userDetails.Signature || '',
    },
    validationSchema: updateDetailsSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const formdata = new FormData();
      formdata.append('firstname', values.firstName);
      formdata.append('lastname', values.lastName);
      formdata.append('address', values.address);
      formdata.append('phone', values.phone);
      formdata.append('signature', values.signature);

      if (userDetails?.AgentType === 'Agent') {
        dispatch(updateAgentProfile({ formdata, agentId: userDetails?._id }));
      } else {
        dispatch(updateSurveyorProfile({ formdata, surveyorId: userDetails?._id }));
      }
    },
  });

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          dispatch(reset());
        },
      });
    }

    if (isSuccess && message === 'Profile Updated Successfully') {
      toast.success('Profile updated successfully', {
        onClose: () => {
          formik.resetForm();
          dispatch(reset());
        },
      });
      dispatch(reset());
    }
  }, [isError, isSuccess, dispatch, message]);

  const inputFields = [
    {
      name: 'firstName',
      label: 'First Name',
      icon: <Person />,
      half: true,
    },
    {
      name: 'lastName',
      label: 'Last Name',
      icon: <Person />,
      half: true,
    },
    {
      name: 'address',
      label: 'Address',
      icon: <Home />,
      half: false,
    },
    {
      name: 'phone',
      label: 'Phone',
      icon: <Phone />,
      half: true,
    },
    {
      name: 'signature',
      label: 'Upload Signature',
      type: 'file',
      half: false,
    },
  ];

  const readOnlyFields = [
    {
      name: 'email',
      label: 'Email',
      value: userDetails.Email,
      icon: <Email />,
      half: true,
    },
    {
      name: 'license',
      label: 'License',
      value: userDetails.License || userDetails?.AgentLicense,
      icon: <Badge />,
      half: false,
    },
    {
      name: 'agentType',
      label: 'Agent Type',
      value: userDetails.AgentType,
      icon: <Person />,
      half: false,
    },
    {
      name: 'licenseYear',
      label: 'License Year',
      value: userDetails.LicenseYear?.split('T')[0] || 'Not Applicable',
      icon: <CalendarMonth />,
      half: false,
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {inputFields.map((field) => (
          <Grid item xs={12} sm={field.half ? 6 : 12} key={field.name}>
            {field.type === 'file' ? (
              <TextField
                fullWidth
                id={field.name}
                name={field.name}
                type="file"
                label={field.label}
                InputLabelProps={{ shrink: true }}
                onChange={(event) => formik.setFieldValue(field.name, event.currentTarget.files[0])}
                error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                helperText={formik.touched[field.name] && formik.errors[field.name]}
              />
            ) : (
              <TextField
                fullWidth
                id={field.name}
                name={field.name}
                label={field.label}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                helperText={formik.touched[field.name] && formik.errors[field.name]}
                InputProps={{
                  startAdornment: field.icon && <InputAdornment position="start">{field.icon}</InputAdornment>,
                }}
              />
            )}
          </Grid>
        ))}

        {readOnlyFields.map((field) => (
          <Grid item xs={12} sm={field.half ? 6 : 4} key={field.name}>
            <TextField
              fullWidth
              label={field.label}
              value={field.value}
              disabled
              InputProps={{
                startAdornment: field.icon && <InputAdornment position="start">{field.icon}</InputAdornment>,
              }}
            />
          </Grid>
        ))}

        <Grid item xs={12} className="space-x-2">
          <Button
            type="submit"
            variant="contained"
            disableElevation
            className="bg-blue-800"
            color="primary"
            size="medium"
            disabled={isLoading || !formik.isValid}
          >
            {isLoading ? 'Updating...' : 'Update Details'}
          </Button>

          <Link to="/dashboard/change-password">
            <Button
              type="submit"
              variant="contained"
              disableElevation
              className="bg-gray-400 "
              color="primary"
              size="medium"
              disabled={isLoading || !formik.isValid}
            >
              Change Password
            </Button>
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default UpdateDetailsForm;
