import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunkWithHandler } from '../api';
import titlePefectionService from './titlePerfectionService';

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};
export const uploadtitlePefectionDocument = createAsyncThunkWithHandler(
  'titlePerfection/uploadtitlePefectionDocument',
  async (data, thunkAPI) => {
    const token = thunkAPI.getState().agentAuth.user.token;
    const formdata = data.formData;
    const response = await titlePefectionService.uploadtitlePefectionDocument(token, data, formdata);
    return response;
  }
);

const titlePerfectionSlice = createSlice({
  name: 'titlePerfection',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadtitlePefectionDocument.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadtitlePefectionDocument.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.message = 'Upload successful';
        state.isSuccess = true;
      })
      .addCase(uploadtitlePefectionDocument.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      });
  },
});

export const { reset } = titlePerfectionSlice.actions;
export default titlePerfectionSlice.reducer;
