import { useEffect } from 'react';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { reset, uploadtitlePefectionDocument } from '../../features/titlePerfection/titlePerfectionSlice';

import { titlePerfectionSchema } from '../../utils/Schemas';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function TitlePerfectionModal({ open, handleClose, documentType }) {
  const dispatch = useDispatch();
  const { docId } = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.agentAuth);
  const { isLoading, isSuccess, isError, message } = useSelector((state) => state.titlePerfection);

  useEffect(() => {
    if (isError) {
      toast.error(message);
      dispatch(reset());
    }

    if (isSuccess && message) {
      toast.success(message);
      handleClose();
      dispatch(reset());
      navigate(0);
    }
  }, [isSuccess, isError, message]);

  const getModalTitle = () => {
    switch (documentType) {
      case 'pricing':
        return 'Upload Title Perfection Pricing Document';
      case 'final':
        return 'Upload Title Perfection Final Document';
      default:
        return 'Upload Document';
    }
  };

  const getUploadLabel = () => {
    switch (documentType) {
      case 'pricing':
        return 'Pricing Document Upload';
      case 'final':
        return 'Final Document Upload';
      default:
        return 'Document Upload';
    }
  };

  const { errors, touched, isSubmitting, setFieldValue, handleSubmit, values, handleChange } = useFormik({
    initialValues: {
      titlePerfection: '',
      price: documentType === 'pricing' ? '' : 0,
    },
    validationSchema: titlePerfectionSchema,
    onSubmit: (values, actions) => {
      console.log(values);
      const formData = new FormData();
      formData.append('titlePerfection', values.titlePerfection);
      formData.append('License', user?.AgentLicense);
      formData.append('documentType', documentType);
      if (documentType === 'pricing') {
        formData.append('TitlePerfectionAmount', values.price);
      }
      dispatch(
        uploadtitlePefectionDocument({
          formData,
          Docid: docId,
          Agentid: user?._id,
        })
      );
    },
  });

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {getModalTitle()}
          </Typography>
          <Box
            onSubmit={isSubmitting ? () => {} : handleSubmit}
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '100%' },
              padding: 0,
              margin: '5% 0%',
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              {documentType === 'pricing' && (
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth
                    id="price"
                    name="price"
                    label="Confirmed Price"
                    type="number"
                    value={values.price}
                    onChange={handleChange}
                    error={touched.price && Boolean(errors.price)}
                    helperText={touched.price && errors.price}
                    InputProps={{
                      startAdornment: <span style={{ marginRight: '8px' }}>₦</span>,
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12}>
                <h3>{getUploadLabel()}</h3>
                <div className="border-2 p-2">
                  <input
                    id="titlePefection"
                    type="file"
                    style={{ display: '' }}
                    accept=".pdf"
                    onChange={(e) => setFieldValue('titlePerfection', e.target.files?.[0] || null)}
                  />
                </div>

                {errors.titlePefection && touched.titlePefection && (
                  <Typography sx={{ fontSize: '12px', color: 'red' }}>{errors.titlePefection}</Typography>
                )}
              </Grid>
            </Grid>

            <Button
              variant="contained"
              disabled={isLoading}
              sx={{
                bgcolor: '#1e40af',
                textTransform: 'none',
                fontWeight: 'bold',
                marginTop: '3%',
              }}
              className="bg-blue-700 w-full"
              size="medium"
              type="submit"
            >
              {isLoading ? 'Please wait...' : 'Upload'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
