import axios from 'axios';
import { API_URL } from '../api';

const uploadtitlePefectionDocument = async (token, data, formData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${API_URL}/titlePerfection/agent/${data.Agentid}/doc/${data.Docid}/`,
    formData,
    config
  );

  return response.data;
};

const titlePerfectionService = {
  uploadtitlePefectionDocument,
};
export default titlePerfectionService;
