import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useFormik } from 'formik';
import { Grid, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { ConfirmSchema } from '../../utils/Schemas';
import { verifyDocumentStatusByAgent } from '../../features/propertydocument/propertydocumentSlice';
import { verifyLandDocumentStatusByAgent } from '../../features/landdocument/landdocumentSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '90vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function ConfirmModal({ open, handleClose, triggeredFrom, document, isLoading }) {
  const dispatch = useDispatch();
  const { docId } = useParams();
  const { user } = useSelector((state) => state.agentAuth);
  console.log(document);

  const { values, errors, touched, isSubmitting, setFieldValue, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      // Existing fields
      LandRegistry: '',
      Comment: '',
      ProbateRegistry: '',
      CacSearch: '',
      AgentUnbalancedPayment: '',

      // New fields - Land Registry Search Details
      searchConductedAt: '',
      departmentAndRoomNumber: '',
      dateOfSearch: '',
      searchConductedBy: '',
      departmentHead: '',

      // Search Findings
      registeredOwners: '',
      titleDocuments: '',
      hasEncumbrances: false,
      encumbrancesDetails: '',
      hasDisputes: false,
      disputesDetails: '',
      isCompliant: false,
      complianceDetails: '',
      isSubjectToAcquisition: false,
      acquisitionDetails: '',
      sizeMeasurement: '',
      measurementScale: document?.land?.Measurement || document?.property?.Measurement,

      // Recommendations
      isSuitableForPurchase: false,
      issues: '',
      notRecommendedReason: '',
    },
    validationSchema: ConfirmSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      const formData = new FormData();

      // Existing form data
      formData.append('Comment', values.Comment);
      for (let index = 0; index < values.LandRegistry.length; index += 1) {
        formData.append('LandRegistory', values.LandRegistry[index]);
      }
      for (let index = 0; index < values.ProbateRegistry.length; index += 1) {
        formData.append('ProbateRegistory', values.ProbateRegistry[index]);
      }
      for (let index = 0; index < values.CacSearch.length; index += 1) {
        formData.append('CacSearch', values.CacSearch[index]);
      }
      formData.append('AgentUnbalancedPayment', values.AgentUnbalancedPayment.replace(/,/g, ''));

      // New form data
      formData.append('searchConductedAt', values.searchConductedAt);
      formData.append('departmentAndRoomNumber', values.departmentAndRoomNumber);
      formData.append('dateOfSearch', values.dateOfSearch);
      formData.append('searchConductedBy', values.searchConductedBy);
      formData.append('departmentHead', values.departmentHead);
      formData.append('registeredOwners', values.registeredOwners);
      formData.append('titleDocuments', values.titleDocuments);
      formData.append('hasEncumbrances', values.hasEncumbrances);
      formData.append('encumbrancesDetails', values.encumbrancesDetails);
      formData.append('hasDisputes', values.hasDisputes);
      formData.append('disputesDetails', values.disputesDetails);
      formData.append('isCompliant', values.isCompliant);
      formData.append('complianceDetails', values.complianceDetails);
      formData.append('isSubjectToAcquisition', values.isSubjectToAcquisition);
      formData.append('acquisitionDetails', values.acquisitionDetails);
      formData.append('isSuitableForPurchase', values.isSuitableForPurchase);
      formData.append('issues', values.issues);
      formData.append('notRecommendedReason', values.notRecommendedReason);

      formData.append('lawyerName', `${user?.Firstname} ${user?.Lastname}`);
      formData.append('phoneNumber', user?.Phone);
      formData.append('scNumberCallYear', user?.AgentLicense);
      formData.append('officeAddress', user?.Address);
      formData.append('signature', user?.Signature);

      formData.append('clientName', `${document?.user?.Firstname} ${document?.user?.Lastname}`);
      formData.append('clientContactInformation', document?.user?.Phone);

      formData.append('location', document?.land?.Address || document?.property?.Address);
      formData.append('sizeMeasurement', values.sizeMeasurement);
      formData.append('measurementScale', document?.land?.Measurement || document?.property?.Measurement);
      formData.append('purchasePrice', document?.document?.AgentConfirmedPrice);

      if (user.AgentType === 'Agent' && triggeredFrom === 'LandedProperty') {
        dispatch(verifyLandDocumentStatusByAgent({ formData, docId }));
      } else if (user.AgentType === 'Agent' && triggeredFrom !== 'LandedProperty') {
        dispatch(verifyDocumentStatusByAgent({ formData, docId }));
      }
    },
  });

  // Existing handlers
  const handleLandRegistryUpload = (event) => {
    setFieldValue('LandRegistry', event.target.files);
  };

  const handleProbateRegistryUpload = (event) => {
    setFieldValue('ProbateRegistry', event.target.files);
  };

  const handleCacSearch = (event) => {
    setFieldValue('CacSearch', event.target.files);
  };

  const formatNumber = (value) => {
    const number = value.replace(/\D/g, '');
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handlePriceChange = (event, name) => {
    const rawValue = event.target.value;
    const numericValue = rawValue.replace(/[^0-9]/g, '');
    const formattedValue = formatNumber(numericValue);
    setFieldValue(name, formattedValue);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Upload Verification Receipts and Reports
          </Typography>
          <Box
            onSubmit={isSubmitting ? () => {} : handleSubmit}
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '100%' },
              padding: 0,
              margin: '5% 0%',
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              {/* Existing Payment Section */}
              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <h3 htmlFor="">Payment Due</h3>
                  <span className="text-gray-600 text-[10px] leading-tight">
                    (*) Please enter the balance to be paid for the process. Enter 0.00 if not applicable
                  </span>
                  <TextField
                    id="AgentUnbalancedPayment"
                    size=""
                    inputProps={{ style: { fontSize: 12 } }}
                    onChange={(e) => handlePriceChange(e, 'AgentUnbalancedPayment')}
                    value={values.AgentUnbalancedPayment}
                    onBlur={(e) => handlePriceChange(e, 'AgentUnbalancedPayment')}
                  />
                </div>
                {errors.AgentUnbalancedPayment && touched.AgentUnbalancedPayment && (
                  <Typography sx={{ fontSize: '9px', color: 'red' }}>{errors.AgentUnbalancedPayment}</Typography>
                )}
              </Grid>

              {/* Existing File Upload Section */}
              <Grid item xs={12} sm={4} md={4}>
                <h3>Lands Registry</h3>
                <div className="border-2 p-2">
                  <input
                    id="LandRegistry"
                    type="file"
                    style={{ display: '' }}
                    accept=".pdf"
                    onChange={handleLandRegistryUpload}
                  />
                </div>
                {errors.LandRegistry && touched.LandRegistry && (
                  <Typography sx={{ fontSize: '12px', color: 'red' }}>{errors.LandRegistry}</Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <h3>Probate Registry</h3>
                <div className="border-2 p-2">
                  <input
                    id="ProbateRegistry"
                    type="file"
                    style={{ display: '' }}
                    accept=".pdf"
                    onChange={handleProbateRegistryUpload}
                  />
                </div>
                {errors.ProbateRegistry && touched.ProbateRegistry && (
                  <Typography sx={{ fontSize: '12px', color: 'red' }}>{errors.ProbateRegistry}</Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <h3>CAC Search</h3>
                <div className="border-2 p-2">
                  <input id="CacSearch" type="file" style={{ display: '' }} accept=".pdf" onChange={handleCacSearch} />
                </div>
                {errors.CacSearch && touched.CacSearch && (
                  <Typography sx={{ fontSize: '12px', color: 'red' }}>{errors.CacSearch}</Typography>
                )}
              </Grid>

              {/* New Sections */}
              {/* Land Registry Search Details Section */}
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                  Land Registry Search Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Search Conducted At"
                      name="searchConductedAt"
                      value={values.searchConductedAt}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Department and Room Number"
                      name="departmentAndRoomNumber"
                      value={values.departmentAndRoomNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      type="date"
                      label="Date of Search"
                      name="dateOfSearch"
                      value={values.dateOfSearch}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label="Search Conducted By"
                      name="searchConductedBy"
                      value={values.searchConductedBy}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label="Department Head"
                      name="departmentHead"
                      value={values.departmentHead}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Search Findings Section */}
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                  Search Findings
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Registered Owners"
                      name="registeredOwners"
                      value={values.registeredOwners}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      name="measurementScale"
                      disabled
                      value={values.measurementScale}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Size Measurement"
                      name="sizeMeasurement"
                      value={values.sizeMeasurement}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Title Documents"
                      name="titleDocuments"
                      value={values.titleDocuments}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      multiline
                      helperText="Enter title documents separated by commas"
                    />
                  </Grid>

                  {/* Encumbrances */}
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.hasEncumbrances}
                          onChange={(e) => setFieldValue('hasEncumbrances', e.target.checked)}
                          name="hasEncumbrances"
                        />
                      }
                      label="Has Encumbrances"
                    />
                    {values.hasEncumbrances && (
                      <TextField
                        fullWidth
                        label="Encumbrances Details"
                        name="encumbrancesDetails"
                        value={values.encumbrancesDetails}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        multiline
                        rows={2}
                      />
                    )}
                  </Grid>

                  {/* Disputes */}
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.hasDisputes}
                          onChange={(e) => setFieldValue('hasDisputes', e.target.checked)}
                          name="hasDisputes"
                        />
                      }
                      label="Has Disputes/Litigation"
                    />
                    {values.hasDisputes && (
                      <TextField
                        fullWidth
                        label="Disputes Details"
                        name="disputesDetails"
                        value={values.disputesDetails}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        multiline
                        rows={2}
                      />
                    )}
                  </Grid>

                  {/* Land Use Compliance */}
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isCompliant}
                          onChange={(e) => setFieldValue('isCompliant', e.target.checked)}
                          name="isCompliant"
                        />
                      }
                      label="Is Land Use Compliant"
                    />
                    {!values.isCompliant && (
                      <TextField
                        fullWidth
                        label="Non-compliance Details"
                        name="complianceDetails"
                        value={values.complianceDetails}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        multiline
                        rows={2}
                      />
                    )}
                  </Grid>

                  {/* Government Acquisition */}
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isSubjectToAcquisition}
                          onChange={(e) => setFieldValue('isSubjectToAcquisition', e.target.checked)}
                          name="isSubjectToAcquisition"
                        />
                      }
                      label="Subject to Government Acquisition"
                    />
                    {values.isSubjectToAcquisition && (
                      <TextField
                        fullWidth
                        label="Acquisition Details"
                        name="acquisitionDetails"
                        value={values.acquisitionDetails}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        multiline
                        rows={2}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* Recommendations Section */}
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                  Recommendations
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isSuitableForPurchase}
                          onChange={(e) => setFieldValue('isSuitableForPurchase', e.target.checked)}
                          name="isSuitableForPurchase"
                        />
                      }
                      label="Suitable for Purchase"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Issues (if any)"
                      name="issues"
                      value={values.issues}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      multiline
                      rows={2}
                    />
                  </Grid>
                  {!values.isSuitableForPurchase && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Reason Not Recommended"
                        name="notRecommendedReason"
                        value={values.notRecommendedReason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        multiline
                        rows={2}
                        required
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              disabled={isLoading}
              sx={{
                bgcolor: '#1e40af',
                textTransform: 'none',
                fontWeight: 'bold',
                marginTop: '3%',
              }}
              className="bg-blue-700 w-full"
              size="medium"
              type="submit"
            >
              {isLoading ? 'Please wait...' : 'Confirm Document Validity'}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
