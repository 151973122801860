import { Card, CardContent } from '@mui/material';

const ProcessSteps = ({ userType = 'Agent' }) => {
  const agentSteps = [
    {
      title: 'Document Assignment',
      description: 'Check unverified documents section for your assigned documents',
      agent: true,
    },
    {
      title: 'Seller Details Verification',
      description: 'Confirm seller details, property price, and escrow amount',
      agent: true,
    },
    {
      title: 'Due Diligence',
      description: 'Complete CAC search, probate registry, and land registry searches. Upload findings and comments',
      agent: true,
    },
    {
      title: 'Deed of Sale',
      description: 'Generate and upload deed of sale document after full payment',
      agent: true,
    },
    {
      title: 'Buyer Signature',
      description: "Wait for buyer's signature notification",
      agent: true,
    },
    {
      title: 'Final Documentation',
      description: 'Upload the final deed of sale document',
      agent: true,
    },
    {
      title: 'Title Perfection Initiation',
      description: 'Generate price draft when buyer initiates title perfection',
      agent: true,
    },
    {
      title: 'Title Perfection Completion',
      description: 'Upload title perfection document after payment confirmation',
      agent: true,
    },
  ];

  const surveyorSteps = [
    {
      title: 'Check Unresolved Surveys',
      description: 'Review unresolved surveys in your dashboard',
      surveyor: true,
    },
    {
      title: 'Conduct Survey & Initial Draft',
      description: 'Conduct survey and upload draft survey with associated fees',
      surveyor: true,
    },
    {
      title: 'Complete Survey Documentation',
      description: 'After full payment, upload complete survey with SURICON number',
      surveyor: true,
    },
  ];

  const steps = userType === 'Agent' ? agentSteps : surveyorSteps;

  return (
    <Card className="mb-8 p-6">
      <CardContent className="space-y-6">
        <h3 className="text-xl font-semibold mb-4">
          {userType === 'Agent' ? 'Property Documentation Process' : 'Survey Process'}
        </h3>
        <div className="space-y-4">
          {steps.map((step, index) => (
            <div key={index} className="flex items-start gap-4">
              <div className="mt-1">
                <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center text-blue-600">
                  {index + 1}
                </div>
              </div>
              <div className="flex-1">
                <div className="flex items-center gap-2">
                  <span className="font-medium">{step.title}</span>
                  {(step.agent || step.surveyor) && (
                    <span className="text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded">
                      {step.agent ? 'Agent Task' : 'Surveyor Task'}
                    </span>
                  )}
                </div>
                <p className="text-sm text-gray-600 mt-1">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default ProcessSteps;
