import axios from 'axios';
import { API_URL } from '../api';

// const API_URL_SURVEYOR = 'http://localhost:5000/LYL/surveyors/';
// const API_URL_AGENT = 'http://localhost:5000/LYL/agents/';

// const API_URL_SURVEYOR = 'https://lockyourlands.uw.r.appspot.com/LYL/surveyors/';
// const API_URL_AGENT = 'https://lockyourlands.uw.r.appspot.com/LYL/agents/';

const logout = async () => {
  localStorage.removeItem('Agentuser');
};

export const logoutError = () => {
  localStorage.removeItem('Agentuser');
};

const login = async (userData) => {
  const response = await axios.post(`${API_URL}/surveyors/login`, userData);

  if (response.data) {
    localStorage.setItem('Agentuser', JSON.stringify(response.data));
  }
  return response.data;
};

const forgotPasswordSurveyor = async (userData) => {
  const response = await axios.post(`${API_URL}/surveyors/forgot_password`, userData);

  return response.data;
};

const register = async (userData) => {
  const response = await axios.post(`${API_URL}/surveyors`, userData);
  return response.data;
};

const verifySurveyor = async (data) => {
  const response = await axios.post(`${API_URL}/surveyors/verify/${data.id}/${data.string}`);
  return response.data;
};

const changePasswordSurveyors = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${API_URL}/surveyors/${userData.surveyorId}/update_password`, userData, config);
  return response.data;
};

const updateSurveyorProfile = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${API_URL}/surveyors/${userData.surveyorId}/update_profile`,
    userData.formdata,
    config
  );

  if (response.data) {
    localStorage.setItem('Agentuser', JSON.stringify(response.data));
  }

  return response.data;
};

const logoutAgent = async () => {
  localStorage.removeItem('Agentuser');
};

const loginAgent = async (userData) => {
  const response = await axios.post(`${API_URL}/agents/login`, userData);

  if (response.data) {
    localStorage.setItem('Agentuser', JSON.stringify(response.data));
  }

  return response.data;
};

const registerAgent = async (userData) => {
  const response = await axios.post(`${API_URL}/agents`, userData);
  return response.data;
};

const verifyAgent = async (data) => {
  const response = await axios.post(`${API_URL}/agents/verify/${data.id}/${data.string}`);
  return response.data;
};

const forgotPasswordAgent = async (userData) => {
  const response = await axios.post(`${API_URL}/agents/forgot_password`, userData);

  return response.data;
};

const changePasswordAgent = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(`${API_URL}/agents/${userData.agentId}/update_password`, userData, config);

  return response.data;
};

const updateAgentProfile = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${API_URL}/agents/${userData.agentId}/update_profile`, userData.formdata, config);
  if (response.data) {
    localStorage.setItem('Agentuser', JSON.stringify(response.data));
  }
  return response.data;
};

const getAllBanks = async () => {
  const response = await axios.get(`https://api.paystack.co/bank`);

  if (response.data) {
    localStorage.setItem('allBanks', JSON.stringify(response.data));
  }
  return response.data;
};

const authService = {
  logout,
  register,
  verifySurveyor,
  login,
  registerAgent,
  loginAgent,
  verifyAgent,
  logoutAgent,
  forgotPasswordSurveyor,
  forgotPasswordAgent,
  changePasswordSurveyors,
  changePasswordAgent,
  updateSurveyorProfile,
  updateAgentProfile,
  getAllBanks,
};

export default authService;
