import axios from 'axios';
import { API_URL } from '../api';

const getDocument = async (token, id, documentId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}/surveyors/surveyor/property/${documentId}/${id}`, config);

  return response.data;
};

const getDocumentByAgent = async (token, id, documentId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}/agents/agent/property/${documentId}/${id}`, config);

  return response.data;
};

const verifyDocumentStatusByAgent = async (token, id, documentId, formdata) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/agents/agent/verify_property_land/${documentId}/${id}`,
    formdata,
    config
  );

  return response.data;
};

const verifySurveyStatus = async (token, id, documentId, formdata) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/surveyors/surveyor/verify_land_property_survey/${documentId}/${id}`,
    formdata,
    config
  );

  return response.data;
};

const finalSurveyUpload = async (token, id, documentId, formdata) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/surveyors/surveyor/final_land_property_survey/${documentId}/${id}`,
    formdata,
    config
  );

  return response.data;
};

const FlagSurveyStatus = async (token, id, documentId, formdata) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/surveyors/surveyor/flag_land_property_survey/${documentId}/${id}`,
    formdata,
    config
  );

  return response.data;
};

const flagDocumentStatusByAgent = async (token, id, documentId, formdata) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/agents/agent/unverify_property_land/${documentId}/${id}`,
    formdata,
    config
  );

  return response.data;
};

const confirmPriceAddSellersInfo = async (token, id, documentId, sellersPriceInfo) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/agents/agent/confirm_price_property_land/${documentId}/${id}`,
    sellersPriceInfo,
    config
  );

  return response.data;
};

const documentService = {
  getDocument,
  verifySurveyStatus,
  getDocumentByAgent,
  verifyDocumentStatusByAgent,
  flagDocumentStatusByAgent,
  FlagSurveyStatus,
  confirmPriceAddSellersInfo,
  finalSurveyUpload,
};
export default documentService;
